import { CHAIN_IDS, INFO, ACTIVED_ID } from './chains';

export const MARKETPLACE = process.env.REACT_APP_MP_ADDRESS as string;
export const MP_VERSION = process.env.REACT_APP_MP_VERSION as string;
export const NFT = process.env.REACT_APP_NFT as string;
export const BATCH_ADDRESS = process.env.REACT_APP_BATCH_TRANSFER as string;

export const TOKEN_BRIDGE = {
  l1GatewayRouter: process.env.REACT_APP_L1_GATEWAY_ROUTER,
  l2GatewayRouter: process.env.REACT_APP_L2_GATEWAY_ROUTER,
  l1CustomGateway: process.env.REACT_APP_L1_CUSTOM_GATEWAY,
  l2CustomGateway: process.env.REACT_APP_L2_CUSTOM_GATEWAY
};

export const L1_RPC =
  ACTIVED_ID === CHAIN_IDS.ETH
    ? INFO[CHAIN_IDS.ETH].rpcUrls[0]
    : INFO[CHAIN_IDS.GOERLI].rpcUrls[0];

export const L2_RPC =
  ACTIVED_ID === CHAIN_IDS.ETH
    ? INFO[CHAIN_IDS.ARBITRUM].rpcUrls[0]
    : INFO[CHAIN_IDS.ARB_GOERLI].rpcUrls[0];
