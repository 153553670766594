import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserWeb3, useTryConnect, isMobile } from '@moverfinance/web3';
import styles from './wallet.module.css';
import { ExternalLink, ModalV2, ButtonV2 } from 'components';
import { track, trackName } from 'vendor/mixpanel';
import { injected } from 'connectors/injected';
import walletConnect from 'connectors/walletconnect';
import { bitkeep } from 'connectors/bitkeep';
import { runCallback, hasWallet } from 'utils';
import { ReactComponent as MetaMask } from 'assets/icons/metamask.svg';
import { ReactComponent as BitKeep } from 'assets/icons/bitkeep.svg';
import { ReactComponent as WalletConnect } from 'assets/icons/walletconnect.svg';

export interface WalletProps {
  hiddenMenu?: () => void;
  showNetSelect?: boolean;
}

const Wallet: React.FC<WalletProps> = ({
  hiddenMenu,
  showNetSelect = false
}) => {
  const { t } = useTranslation();
  const { error } = useUserWeb3();
  const [callback, setCallback] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const tryConnect = useTryConnect();

  const connectMetaMask = useCallback(() => {
    track(trackName.CONNECT_WALLET);
    tryConnect(injected);
    localStorage.setItem('provider', 'injected');
    setShowWallet(false);
  }, [tryConnect]);

  const connectWalletConnect = useCallback(() => {
    track(trackName.CONNECT_WALLET);
    tryConnect(walletConnect);
    localStorage.setItem('provider', 'walletConnect');
    setShowWallet(false);
  }, [tryConnect]);

  const connectBitKeep = useCallback(() => {
    if (window.isBitKeep) {
      track(trackName.CONNECT_WALLET);
      tryConnect(bitkeep);
      localStorage.setItem('provider', 'bitkeep');
    } else {
      window.open(
        'https://chrome.google.com/webstore/detail/bitkeep-bitcoin-crypto-wa/jiidiaalihmmhddjgbnbgdfflelocpak'
      );
    }
    setShowWallet(false);
  }, [tryConnect]);

  const connectWallet = useCallback(() => {
    if (isMobile) {
      connectMetaMask();
    } else {
      setShowWallet(true);
    }
  }, [connectMetaMask]);

  useEffect(() => {
    if (callback || !isMobile) return;
    if (error) {
      hiddenMenu && runCallback(hiddenMenu, false);
      setCallback(true);
    }
  }, [error, hiddenMenu, callback]);

  return (
    <>
      {hasWallet() ? (
        <button className={styles.wallet} onClick={connectWallet}>
          {t('common.connectWallet')}
        </button>
      ) : (
        <ExternalLink
          className={styles.wallet}
          to={
            isMobile
              ? `https://metamask.app.link/dapp/${window.location.host}`
              : 'https://metamask.io/download.html'
          }
        >
          {t('common.connectWallet')}
        </ExternalLink>
      )}
      <ModalV2
        visible={showWallet}
        title={'Connect to wallet'}
        onClose={setShowWallet}
        className={styles.modal}
      >
        <div className={styles.modalInner}>
          <ButtonV2
            mode="normal"
            className={styles.modalButton}
            onClick={connectMetaMask}
          >
            <MetaMask className={styles.modalIcon} />
            METAMASK
          </ButtonV2>
          <ButtonV2
            mode="normal"
            className={styles.modalButton}
            onClick={connectBitKeep}
          >
            <BitKeep className={styles.modalIcon} />
            BITKEEP
          </ButtonV2>
          <ButtonV2
            mode="normal"
            className={styles.modalButton}
            onClick={connectWalletConnect}
          >
            <WalletConnect className={styles.modalIcon} />
            WALLETCONNECT
          </ButtonV2>
        </div>
      </ModalV2>
    </>
  );
};
export default Wallet;
