import React from 'react';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import Routes from './routes';
import 'styles/index.css';
interface AppProps {
  history: History;
}

const App: React.FC<AppProps> = ({ history }) => {
  return (
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  );
};

export default App;
