import mixpanel from 'mixpanel-browser';

mixpanel.init('e3158fd3a93cf90dd08896662d0936d3', {
  ignore_dnt: true
});

export const trackName = {
  CONNECT_WALLET: 'Connect Wallet',
  CLICK_MY_ACCOUNT: 'Click My Account',
  CLICK_CLASS_FILTER: 'Click Class Filter',
  RARITY_ON_CHANGE: 'Rarity on change',
  CLICK_ITEM: 'Click Item',
  FILTER_ON_CHANGE: 'Filter on change',
  SORT_ON_CHANGE: 'Sort on change',
  CLICK_BUY_NOW: 'Click Buy Now',
  CLICK_ITEM_IN_INVENTORY: 'Click Item in inventory',
  CLICK_OWNER_ADDRESS: 'Click owner address',
  CLICK_SELL: 'Click Sell',
  CLICK_POSTING_FOR_SELL: 'Click Posting for sell'
};

export const track = (eventName: string, attributes?: {}) => {
  mixpanel.track(eventName, {
    ...attributes,
    GA: 'G-D2K6T8G9G6'
  });
};
