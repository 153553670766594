import { BigNumber } from '@ethersproject/bignumber';
import { CHAIN_IDS, INFO } from 'constants/chains';

export const toHex = (value: number | string) => {
  if (value < 10) return `0x${parseInt(value as string, 10)}`;
  return BigNumber.from(value).toHexString();
};

export const shortenAddress = (address: string, chars = 4): string => {
  return `${address.substring(0, chars + 2)}...${address.substring(
    42 - chars
  )}`;
};

export const runCallback = (func: any, ...args: any[]) => {
  if (func && typeof func === 'function') {
    func(...args);
  }
};

export const copyText = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';
  document.body.appendChild(textArea);
  textArea.value = text;
  textArea.focus();
  textArea.select();
  const result = document.execCommand('copy');
  textArea.remove();
  return result;
};

export const noop = () => {};

export const generateId = () => {
  return Math.floor(Math.random() * 10000).toString();
};

export const trimBalance = (balance: string | number) => {
  return Number(Number(balance).toFixed(4)).toString();
};
export function strDate(date: Date | null) {
  if (date) {
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  } else {
    return '';
  }
}
export function strDateTime(date: Date | null) {
  if (date) {
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}  ${date
      .getHours()
      .toString()
      .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  } else {
    return '';
  }
}
export function strEnDateTime(date: Date | null) {
  let monthEnglish = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Spt',
    'Oct',
    'Nov',
    'Dec'
  ];
  if (date) {
    let monthEng = monthEnglish[date.getMonth()];
    return `${monthEng} ${date
      .getDate()
      .toString()
      .padStart(2, '0')}th, ${date.getFullYear()}`;
  } else {
    return '';
  }
}

export function parsePrice(n: string) {
  var re = /\d{1,3}(?=(\d{3})+$)/g;
  var n1 = n.replace(/^(\d+)((\.\d+)?)$/, function (s, s1, s2) {
    return s1.replace(re, '$&,') + s2;
  });
  return n1;
}

export function strEvent(str: string | null) {
  if (str) {
    return str.trim().toLowerCase().replace(str[0], str[0].toUpperCase());
  } else {
    return '';
  }
}
export function strNewWorkFullName(str: string | null) {
  if (str) {
    const chainId = CHAIN_IDS[str];

    return INFO[chainId]?.name ?? str;
  } else {
    return '';
  }
}

export function hasWallet() {
  return window.ethereum || window.isBitKeep;
}
