import cls from 'clsx';
import { ExternalLink } from 'components';
import { ReactComponent as Openblox } from 'assets/icons/openblox.svg';
import { ReactComponent as Gitbook } from 'assets/icons/sns/gitbook.svg';
import { ReactComponent as Discord } from 'assets/icons/sns/discord.svg';
import { ReactComponent as Twitter } from 'assets/icons/sns/twitter.svg';
import { ReactComponent as Medium } from 'assets/icons/sns/medium.svg';
import { ReactComponent as Telegram } from 'assets/icons/sns/telegram.svg';
import { ReactComponent as Snapshot } from 'assets/icons/sns/snapshot.svg';
import styles from './footer.module.css';

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.inner}>
        <div className={styles.body}>
          <div className={styles.footerLogo}>
            <ExternalLink
              to="https://openblox.io"
              className={styles.footerLogoLink}
            >
              <Openblox className={styles.footerLogoIcon} />
            </ExternalLink>
          </div>
          <div className={styles.footerInfo}>
            <div className={styles.footerColumn}>
              <p className={styles.footerTitle}>Contact</p>
              <div className={styles.footerItem}>
                <p className={styles.footerSubTitle}>General inquiry</p>
                <ExternalLink
                  to="mailto:hello@openblox.io"
                  className={styles.footerLink}
                >
                  hello@openblox.io
                </ExternalLink>
              </div>
              <div className={styles.footerItem}>
                <p className={styles.footerSubTitle}>Partnership</p>
                <ExternalLink
                  to="mailto:marketing@openblox.io"
                  className={styles.footerLink}
                >
                  marketing@openblox.io
                </ExternalLink>
              </div>
              <div className={styles.footerItem}>
                <p className={styles.footerSubTitle}>Media</p>
                <ExternalLink
                  to="mailto:media@openblox.io"
                  className={styles.footerLink}
                >
                  media@openblox.io
                </ExternalLink>
              </div>
            </div>
            <div className={cls(styles.footerColumn, styles.footerDownload)}>
              <div className={styles.footerDownloadInner}>
                <p className={styles.footerTitle}>Download</p>
                <div className={styles.footerItem}>
                  <ExternalLink
                    to="https://drive.google.com/drive/u/0/folders/1U9g223IppAUKA4oe_jWO6RhK5tAxEVuO"
                    className={styles.footerLink}
                  >
                    Media Kit
                  </ExternalLink>
                </div>
              </div>
              <div className={styles.footerTerms}>
                <ExternalLink
                  to="https://openblox.io/termsofuse/terms-conditions"
                  className={styles.footerLink}
                >
                  Terms of use
                </ExternalLink>
                <ExternalLink
                  to="https://www.iubenda.com/privacy-policy/30870666"
                  className={styles.footerLink}
                >
                  Privacy
                </ExternalLink>
                <ExternalLink
                  to="https://openblox.zendesk.com/hc/en-001"
                  className={styles.footerLink}
                >
                  Help center
                </ExternalLink>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <p className={styles.copyright}>© 2023 OPENBLOX</p>
          <div className={styles.sns}>
            <ExternalLink
              to="https://docs.openblox.io/openblox/master"
              className={styles.snsLink}
            >
              <Gitbook className={styles.snsIcon} />
            </ExternalLink>
            <ExternalLink
              to="https://discord.gg/openblox"
              className={styles.snsLink}
            >
              <Discord className={styles.snsIcon} />
            </ExternalLink>
            <ExternalLink
              to="https://twitter.com/OpenBlox_io"
              className={styles.snsLink}
            >
              <Twitter className={styles.snsIcon} />
            </ExternalLink>
            <ExternalLink
              to="https://medium.com/@OpenBlox"
              className={styles.snsLink}
            >
              <Medium className={styles.snsIcon} />
            </ExternalLink>
            <ExternalLink
              to="https://t.me/openblox_eng"
              className={styles.snsLink}
            >
              <Telegram className={styles.snsIcon} />
            </ExternalLink>
            <ExternalLink
              to="https://snapshot.org/#/openbloxdao.eth"
              className={styles.snsLink}
            >
              <Snapshot className={cls(styles.snsIcon, styles.snapshot)} />
            </ExternalLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
