import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Activity, NFT } from 'services';
import styles from './trading-history.module.css';
import { shortenAddress, strDateTime, strEvent } from 'utils';
import { ExternalLink, Table } from 'components';
import { ETHERSCAN_URL } from 'constants/chains';

interface TradingHistoryProps {
  nft: NFT;
  activities: Activity[];
}

const TradingHistory: React.FC<TradingHistoryProps> = ({ nft, activities }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: '',
      key: 'marginL',
      width: '10px',
      render: (value: string) => {
        if (value) {
          return <span></span>;
        }
      }
    },
    {
      title: 'EVENT',
      key: 'event',
      width: '41px',
      render: (value: string) => {
        if (value) {
          return <span className={styles.event}>{value}</span>;
        }
      }
    },
    {
      title: 'UNIT PRICE',
      key: 'price',
      width: '90px',
      render: (value: string) => {
        if (value) {
          return <span>{value} eth</span>;
        }
      }
    },
    {
      title: 'FROM',
      key: 'from',
      width: '91px',
      render: (value: string) => {
        if (value) {
          return (
            <ExternalLink
              to={`${ETHERSCAN_URL}/address/${value}`}
              className={styles.externalLink}
            >
              {shortenAddress(value)}
            </ExternalLink>
          );
        } else {
          return null;
        }
      }
    },
    {
      title: 'TO',
      key: 'to',
      width: '91px',
      render: (value: string) => {
        if (value) {
          return (
            <ExternalLink
              to={`${ETHERSCAN_URL}/address/${value}`}
              className={styles.externalLink}
            >
              {shortenAddress(value)}
            </ExternalLink>
          );
        } else {
          return null;
        }
      }
    },
    {
      title: 'DATE',
      key: 'date',
      width: '65px'
    },
    {
      title: '',
      key: 'marginR',
      width: '10px',
      render: (value: string) => {
        if (value) {
          return <span></span>;
        }
      }
    }
  ];

  const dataSource = activities.map(activity => {
    return {
      marginL: '',
      event: strEvent(activity.event),
      price: activity.price || '',
      from: activity.from || '',
      to: activity.to || '',
      date: strDateTime(new Date(activity.ts)),
      marginR: ''
    };
  });

  return (
    <div className={styles.history}>
      <h1 className={styles.header}>{t('views.asset.tradingHistory')}</h1>
      <div className={styles.activity}>
        <Table columns={columns} dataSource={dataSource} />
      </div>
    </div>
  );
};

export default memo(TradingHistory);
