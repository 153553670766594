import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import {
  SUPPORTED_IDS as SUPPORTED_CHAIN_IDS,
  ACTIVED_ID,
  WALLETCONNECT_RPC_URL
} from 'constants/chains';

export default new WalletConnectConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
  chainId: ACTIVED_ID,
  rpc: WALLETCONNECT_RPC_URL,
  qrcodeModalOptions: {
    mobileLinks: ['metamask'],
    desktopLinks: ['metamask']
  },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true
});
