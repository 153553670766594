import { isMainnet } from 'utils/network';
import { strEvent, strNewWorkFullName } from '../utils';
const ETH = 1;
const GOERLI = 5;
const ARBITRUM = 42161;
const ARB_GOERLI = 421613;

export const CHAIN_IDS: Record<string, number> = {
  ETH,
  GOERLI,
  ARBITRUM,
  ARB_GOERLI
} as const;

export const INFO = {
  [CHAIN_IDS.ETH]: {
    chainID: CHAIN_IDS.ETH,
    name: 'Ethereum',
    explorerUrl: 'https://etherscan.io',
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 }
  },
  [CHAIN_IDS.ARBITRUM]: {
    chainID: CHAIN_IDS.ARBITRUM,
    name: 'Arbitrum',
    explorerUrl: 'https://arbiscan.io',
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 }
  },
  [CHAIN_IDS.GOERLI]: {
    chainID: CHAIN_IDS.GOERLI,
    name: 'Goerli',
    explorerUrl: 'https://goerli.etherscan.io',
    rpcUrls: ['https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 }
  },
  [CHAIN_IDS.ARB_GOERLI]: {
    chainID: CHAIN_IDS.ARB_GOERLI,
    name: 'Arbitrum Goerli',
    explorerUrl: 'https://goerli-rollup-explorer.arbitrum.io',
    rpcUrls: ['https://goerli-rollup.arbitrum.io/rpc/'],
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 }
  }
};

export const WALLETCONNECT_RPC_URL = {
  [CHAIN_IDS.ETH]:
    'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  [CHAIN_IDS.ARBITRUM]: 'https://arb1.arbitrum.io/rpc',
  [CHAIN_IDS.GOERLI]:
    'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  [CHAIN_IDS.ARB_GOERLI]: 'https://goerli-rollup.arbitrum.io/rpc/'
};

export const SUPPORTED_IDS = isMainnet ? [ETH, ARBITRUM] : [GOERLI, ARB_GOERLI];
export const ACTIVED_ID =
  CHAIN_IDS[process.env.REACT_APP_ACTIVED_KEY as string] || ETH;

export const ETHERSCAN_URL = INFO[ACTIVED_ID]?.explorerUrl ?? '';

const L1NET = ['ETH', 'GOERLI'];

export const envNetworkKey = strEvent(
  strNewWorkFullName(process.env.REACT_APP_ACTIVED_KEY as string).toLowerCase()
);

export const isL1NET = (
  key: string = process.env.REACT_APP_ACTIVED_KEY as string
) => {
  return L1NET.some(net => net === key);
};

export const L1Name = strEvent(
  strNewWorkFullName(process.env.REACT_APP_L1_NAME as string).toLowerCase()
);
export const L2Name = strEvent(
  strNewWorkFullName(process.env.REACT_APP_L2_NAME as string).toLowerCase()
);
