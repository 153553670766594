import { useCallback, useEffect, useState } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserWeb3 } from '@moverfinance/web3';
import styles from './asset.module.css';
import { useCancel } from 'hooks';
import { Button, CardOverview, Modal, Wallet, message } from 'components';
import { NFT as NFT_ADDRESS } from 'constants/address';
import {
  Activity,
  Attribute,
  listActivitiesByNFT,
  listAttributes,
  listNFTs,
  listOrders,
  NFT,
  Order
} from 'services';
import BloxInfo from './BloxInfo';
// import BoxInfo from './BoxInfo';
import TradingHistory from './TradingHistory';
import Buy from './Buy';
// import OpenBox from './OpenBox';
// import Breed from './Breed';

import { track, trackName } from 'vendor/mixpanel';

interface ParamTypes {
  assetId: string;
}

type Status = 'buy' | 'sell' | 'cancel' | 'breed' | '';

const Asset: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const cancel = useCancel();
  const { assetId } = useParams<ParamTypes>();
  const { account } = useUserWeb3();
  const [status, setStatus] = useState<Status>('');
  const [showCancel, setShowCancel] = useState(false);
  const [nft, setNft] = useState<NFT>({} as NFT);
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [attribute, setAttribute] = useState<Attribute>();
  const [order, setOrder] = useState<Order>({} as Order);
  const [activities, setActivities] = useState<Activity[]>([]);

  const cancelAsset = useCallback(() => {
    if (order) {
      const { order: cancelOrder } = order;
      cancel(cancelOrder)
        .then(() => {
          setShowCancel(false);
          message.success('Cancel success! Please update your page!');
        })
        .catch(error => {
          message.warning(error.error?.message || error.message, 5);
        });
    }
  }, [order, cancel]);

  const backView = () => {
    history.goBack();
  };

  useEffect(() => {
    const params = {
      nftAddress: NFT_ADDRESS,
      tokenId: assetId
    };
    listNFTs(params).then(data => {
      setNft(data.nfts[0]);
    });
    listAttributes(params).then(data => {
      setAttributes(data.attributes);
      setAttribute(data.attributes?.filter(a => a.key === 'race')[0]);
    });
    listActivitiesByNFT(params).then(data => {
      setActivities(data.activities);
    });
  }, [assetId]);

  useEffect(() => {
    if (nft?.order?.onsale && nft?.order?.orderId) {
      listOrders({
        orderId: nft.order.orderId,
        sig: true
      }).then(data => {
        setOrder(data.orders[data.orders.length - 1]);
      });
    }
  }, [nft]);

  useEffect(() => {
    if (account) {
      if (nft?.owner === account) {
        if (nft?.order?.onsale) {
          setStatus('cancel');
        } else {
          setStatus('sell');
        }
      } else if (nft?.order?.onsale) {
        setStatus('buy');
      }
    } else if (nft?.order?.onsale) {
      setStatus('buy');
    } else {
      setStatus('');
    }
  }, [account, nft]);

  const Trade = () => {
    switch (status) {
      case 'buy': {
        return <Buy nft={nft} order={order} />;
      }
      case 'sell': {
        return (
          <>
            <NavLink
              to={`/assets/${assetId}/sell`}
              className={'trade'}
              onClick={() => {
                track(trackName.CLICK_SELL, { Address: account });
              }}
            >
              {t('views.asset.sell')}
            </NavLink>
            {/* <OpenBox nft={nft} /> */}
            {/* <Breed nft={nft} /> */}
          </>
        );
      }
      case 'cancel': {
        return (
          <Button
            className={`${styles.cancelListing} ${styles.cancelListingLarge}`}
            onClick={() => setShowCancel(true)}
          >
            {t('views.asset.cancelListing')}
          </Button>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <>
      <div className={styles.asset}>
        <div className={styles.inner}>
          <div className={styles.nav}>
            <button
              className={`${styles.button} ${styles.prevBtn}`}
              onClick={backView}
            >
              Back
            </button>
          </div>
          <div className={styles.content}>
            <div className={styles.infoHeaderMobile}>
              <div className={styles.infoDesc}>
                <p className={styles.infoName}>Blox &nbsp;#{nft?.tokenId}</p>
              </div>
            </div>
            <div className={styles.card}>
              <CardOverview
                imgSrc={nft?.imageUrl}
                ethPrice={nft?.order?.price}
                lastPrice={nft?.order?.lastPrice}
                owner={nft?.owner}
                race={attributes.filter(a => a.key === 'race')[0]}
                onsale={nft?.order?.onsale}
              />
              <div className={styles.tradeView}>
                <Trade />
              </div>
            </div>
            <div className={styles.rightView}>
              <BloxInfo nft={nft} attribute={attribute} />
              {/* <BoxInfo nft={nft} /> */}
              <TradingHistory nft={nft} activities={activities} />
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={showCancel}
        title={'Are you sure you want to cancel your listings?'}
        closable={false}
      >
        <div className={styles.cancel}>
          <p className={styles.cancelContent} style={{ color: '#909090' }}>
            Canceling your listing will unpublish this sale from OpenBlox and
            requires a transaction to make sure it will never be fulfillable.
          </p>
          <Wallet />
        </div>
        <div className={styles.cancelFooter}>
          <Button className={`${styles.trade}`} onClick={cancelAsset}>
            {'CANCEL LISTINGS'}
          </Button>

          <Button
            className={`${styles.cancelListing}`}
            onClick={() => setShowCancel(false)}
          >
            {'CANCEL'}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Asset;
