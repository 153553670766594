/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IL1Token,
  IL1TokenInterface,
} from "../../../contracts/ethereum/IL1Token";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "tokendata",
        type: "bytes",
      },
    ],
    name: "bridgeBurn",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "tokendata",
        type: "bytes",
      },
    ],
    name: "bridgeMint",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "isArbitrumEnabled",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "l2CustomTokenAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "maxSubmissionCostForCustomBridge",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxSubmissionCostForRouter",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxGasForCustomBridge",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxGasForRouter",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "gasPriceBid",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "valueForGateway",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "valueForRouter",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "creditBackAddress",
        type: "address",
      },
    ],
    name: "registerTokenOnL2",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
];

export class IL1Token__factory {
  static readonly abi = _abi;
  static createInterface(): IL1TokenInterface {
    return new utils.Interface(_abi) as IL1TokenInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IL1Token {
    return new Contract(address, _abi, signerOrProvider) as IL1Token;
  }
}
