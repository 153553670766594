import React from 'react';
import { NFT, Attribute } from 'services';
import { strEnDateTime } from 'utils';
import CardRace from 'components/Card/CardRace';
import { ExternalLink } from 'components';
import { ETHERSCAN_URL } from 'constants/chains';
import { ReactComponent as LinkOutside } from 'assets/icons/link-outside.svg';
import { ReactComponent as Head } from 'assets/icons/head.svg';
import { ReactComponent as Arms } from 'assets/icons/arms.svg';
import { ReactComponent as Body } from 'assets/icons/body.svg';
import { ReactComponent as Horns } from 'assets/icons/horns.svg';
import { ReactComponent as Legs } from 'assets/icons/legs.svg';
import { ReactComponent as Tail } from 'assets/icons/tail.svg';
import { track, trackName } from 'vendor/mixpanel';
import styles from './info.module.css';

export interface BloxInfoProps {
  nft: NFT;
  attribute?: Attribute;
}

const BloxInfo: React.FC<BloxInfoProps> = ({ nft, attribute }) => {
  return (
    <div className={styles.info}>
      <div className={styles.infoHeader}>
        <div className={styles.infoDesc}>
          <p className={styles.infoName}>Blox &nbsp;#{nft?.tokenId}</p>
        </div>
      </div>
      <p className={styles.infoCaption}>About</p>
      <div className={styles.infoCard}>
        <div className={styles.infoCardTop}>
          <div className={styles.infoCardTopRow}>
            <p className={styles.infoSubject}>CLASS</p>
            <p className={styles.infoText}>
              <CardRace race={nft?.properties?.race}></CardRace>
              {nft?.properties?.race}
            </p>
          </div>
          <div className={styles.infoCardTopRow}>
            <p className={styles.infoSubject}>BORN AT</p>
            <p className={styles.infoText}>
              {nft?.properties?.bornAt &&
                strEnDateTime(new Date(nft?.properties?.bornAt))}
            </p>
          </div>
          <div className={styles.infoCardTopRow}>
            <p className={styles.infoSubject}>TRAIT</p>
            <div className={styles.infoText}>
              {attribute && (
                <div className={styles.trait}>
                  <p className={styles.traitInfo}>
                    {attribute?.value}
                    {' - '}
                    {((attribute?.count / attribute?.total) * 100).toFixed(0)}%
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <p className={styles.infoSubject}>OWNER</p>
        <div
          className={styles.infoOwner}
          onClick={() =>
            track(trackName.CLICK_OWNER_ADDRESS, {
              Address: nft?.owner
            })
          }
        >
          <ExternalLink
            to={`${ETHERSCAN_URL}/address/${nft.owner}`}
            className={styles.infoOwnerAddress}
          >
            {nft.owner}
            <LinkOutside />
          </ExternalLink>
        </div>
      </div>

      <p className={styles.infoCaption}>Body Parts</p>
      <div className={styles.infoCardBodyParts}>
        <div className={styles.infoCardBodyPartsText}>
          <div className={styles.infoSubject}>BODY</div>
          <div className={styles.infoCardBodyPartsTextValue}>
            <Body />
            <span>{nft?.properties?.body}</span>
          </div>
        </div>
        <div className={styles.infoCardBodyPartsText}>
          <div className={styles.infoSubject}>HEAD</div>
          <div className={styles.infoCardBodyPartsTextValue}>
            <Head />
            <span>{nft?.properties?.head}</span>
          </div>
        </div>
      </div>
      <div className={styles.infoCardBodyParts}>
        <div className={styles.infoCardBodyPartsText}>
          <div className={styles.infoSubject}>ARMS</div>
          <div className={styles.infoCardBodyPartsTextValue}>
            <Arms />
            <span>{nft?.properties?.arm}</span>
          </div>
        </div>
        <div className={styles.infoCardBodyPartsText}>
          <div className={styles.infoSubject}>LEGS</div>
          <div className={styles.infoCardBodyPartsTextValue}>
            <Legs />
            <span>{nft?.properties?.leg}</span>
          </div>
        </div>
      </div>
      <div className={styles.infoCardBodyParts}>
        <div className={styles.infoCardBodyPartsText}>
          <div className={styles.infoSubject}>TAIL</div>
          <div className={styles.infoCardBodyPartsTextValue}>
            <Tail />
            <span>{nft?.properties?.back}</span>
          </div>
        </div>
        <div className={styles.infoCardBodyPartsText}>
          <div className={styles.infoSubject}>HORNS</div>
          <div className={styles.infoCardBodyPartsTextValue}>
            <Horns />
            <span>{nft?.properties?.horn}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BloxInfo;
