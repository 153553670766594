import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserWeb3, useTryConnect, isMobile } from '@moverfinance/web3';
import { UnsupportedChainIdError } from '@web3-react/core';
import { hasWallet } from 'utils';
import styles from './header.module.css';
import { ExternalLink, Wallet } from 'components';
import { ReactComponent as Logo } from 'assets/icons/logo-v2.svg';
import { priceRequest, TypePriceRequest } from 'store/price/actions';
import Account from './Account';
import { injected } from '../../connectors/injected';
import { track, trackName } from 'vendor/mixpanel';

interface HeaderProps {
  priceRequest: TypePriceRequest;
}
const Header: React.FC<HeaderProps> = ({ priceRequest }) => {
  const { active, error, account } = useUserWeb3();
  const { t } = useTranslation();
  const tryConnect = useTryConnect();
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(open => !open);
  };

  useEffect(() => {
    priceRequest();
  }, [priceRequest]);

  useEffect(() => {
    if (error) {
      hiddenMenu();
    }
  }, [error]);

  const hiddenMenu = () => {
    setOpen(open => !open);
  };

  useEffect(() => {
    if (open) {
      document.body.style.setProperty('overflow', 'hidden');
    } else {
      document.body.style.removeProperty('overflow');
    }
  }, [open]);

  return (
    <div className={styles.header}>
      <div className={styles.inner}>
        <div className={styles.innerView}>
          <a
            href={'https://openblox.io'}
            className={styles.logo}
            target={'_blank'}
            rel="noreferrer"
          >
            <Logo />
          </a>
          <div className={styles.navMenu}>
            <NavLink
              className={styles.navItem}
              activeClassName={styles.navItemActive}
              to={'/assets'}
            >
              <div>
                <div className={styles.navItemDisabled}>Marketplace</div>
              </div>
            </NavLink>
            <NavLink
              className={styles.navItem}
              activeClassName={styles.navItemActive}
              to={'/activity'}
            >
              Activity
            </NavLink>
            <a
              className={styles.navItem}
              href={'https://docs.openblox.io/'}
              target={'_blank'}
              rel="noreferrer"
            >
              Whitepaper
            </a>
          </div>
        </div>
        <div className={styles.innerAccount}>
          {hasWallet() ? (
            active ? (
              <div className={styles.innerAccountLink}>
                <a
                  href="/account"
                  className={styles.innerAccountLinkText}
                  onClick={() =>
                    track(trackName.CLICK_MY_ACCOUNT, { Address: account })
                  }
                >
                  {t('layouts.header.account')}
                </a>
              </div>
            ) : (
              <div className={styles.innerAccountLink}>
                <button
                  className={`${
                    error instanceof UnsupportedChainIdError
                      ? styles.walletDisabled
                      : styles.wallet
                  }`}
                  onClick={() => {
                    track(trackName.CONNECT_WALLET);
                    tryConnect(injected);
                  }}
                >
                  {t('common.connectWallet')}
                </button>
              </div>
            )
          ) : isMobile ? (
            <div className={styles.innerAccountLink}>
              <ExternalLink
                className={styles.wallet}
                to={`https://metamask.app.link/dapp/${document.domain}`}
              >
                {t('common.connectWallet')}
              </ExternalLink>
            </div>
          ) : (
            <div className={styles.innerAccountLink}>
              <ExternalLink
                to="https://metamask.io/download.html"
                className={styles.wallet}
              >
                {t('common.installMetaMask')}
              </ExternalLink>
            </div>
          )}
        </div>
        <nav className={`${styles.nav} ${open ? styles.open : ''}`}>
          <div className={styles.navInfo}>
            {active ? (
              <Account />
            ) : (
              <Wallet hiddenMenu={hiddenMenu} showNetSelect={true} />
            )}
          </div>
        </nav>
        <button className={styles.menu} onClick={toggleMenu}>
          <span
            className={`${styles.menuIcon} ${open ? styles.open : ''}`}
          ></span>
        </button>
      </div>
    </div>
  );
};
const mapDispatchToProps = {
  priceRequest
};
export default connect(null, mapDispatchToProps)(Header);
