import React from 'react';
import { ReactComponent as Waiting } from 'assets/icons/waiting.svg';
import styles from './button.module.css';
import cls from 'clsx';
export type ButtonType = 'submit' | 'button' | 'reset';
export type ButtonMode = 'primary' | 'secondary' | 'normal' | 'info' | 'danger';

export interface ButtonProps {
  children: React.ReactNode;
  mode?: ButtonMode;
  type?: ButtonType;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  children,
  mode = 'normal',
  type = 'button',
  disabled = false,
  loading = false,
  className = '',
  onClick
}) => {
  const disableState = React.useMemo(
    () => disabled || loading,
    [disabled, loading]
  );

  return (
    <button
      type={type}
      disabled={disableState}
      className={cls(
        styles.button,
        { [styles[mode]]: !disableState },
        className
      )}
      onClick={onClick}
    >
      {loading && <Waiting className={styles.icon} />}
      {children}
    </button>
  );
};

export default Button;
