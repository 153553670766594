import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useAuthenticated } from 'hooks';
import { BaseLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';

import Account from 'views/Account';
import Asset from 'views/Asset';
import Activity from 'views/Activity';
import Login from 'views/Login';
import Marketplace from 'views/Marketplace';
import Sell from 'views/Sell';
import NotFound from 'views/NotFound';
import { isL1NET } from '../constants/chains';

const Routes = () => {
  const isAuthenticated = useAuthenticated();
  const location = useLocation<{ from: string }>();
  const { from } = location.state || { from: '/' };

  return (
    <BaseLayout>
      <Switch>
        <Redirect exact from="/" to="/assets" />
        <Route path="/login">
          {isAuthenticated ? <Redirect to={from} /> : <Login />}
        </Route>
        {isL1NET() ? (
          <Switch>
            <PrivateRoute exact path="/assets" component={Account} />
            <PrivateRoute exact path="/assets/:assetId" component={Account} />
            <PrivateRoute
              exact
              path="/assets/:assetId/sell"
              component={Account}
            />
            <PrivateRoute exact path="/account" component={Account} />
            <PrivateRoute exact path="/activity" component={Account} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/assets" component={Marketplace} />
            <Route exact path="/assets/:assetId" component={Asset} />
            <Route exact path="/assets/:assetId/sell" component={Sell} />
            <PrivateRoute path="/account" component={Account} />
            <Route exact path="/activity" component={Activity} />
          </Switch>
        )}
        <Route component={NotFound} />
      </Switch>
    </BaseLayout>
  );
};

export default Routes;
