/*
 * SPDX-License-Identifier: Apache-2.0
 * Copyright 2021, Offchain Labs, Inc.
 * Modifications Copyright 2022, chicunic
 */

import { Signer } from '@ethersproject/abstract-signer';
import { Provider, TransactionRequest } from '@ethersproject/abstract-provider';
import { PayableOverrides, Overrides } from '@ethersproject/contracts';
import { BigNumber, ethers } from 'ethers';

import {
  L1ToL2MessageGasEstimator,
  L1TransactionReceipt,
  L2Network
} from '@arbitrum/sdk';
import { L1GatewayRouter__factory } from '@arbitrum/sdk/dist/lib/abi/factories/L1GatewayRouter__factory';
import { ERC721__factory } from 'typechain-types';

import { GasOverrides } from '@arbitrum/sdk/dist/lib/message/L1ToL2MessageGasEstimator';
import { SignerProviderUtils } from '@arbitrum/sdk/dist/lib/dataEntities/signerOrProvider';
import { ArbSdkError } from '@arbitrum/sdk/dist/lib/dataEntities/errors';
import { EthDepositParams } from '@arbitrum/sdk/dist/lib/assetBridger/ethBridger';
import { AssetBridger } from '@arbitrum/sdk/dist/lib/assetBridger/assetBridger';
import { L1ContractCallTransaction } from '@arbitrum/sdk/dist/lib/message/L1Transaction';
import {
  isL1ToL2TransactionRequest,
  L1ToL2TransactionRequest
} from '@arbitrum/sdk/dist/lib/dataEntities/transactionRequest';
import { defaultAbiCoder } from '@ethersproject/abi';
import { OmitTyped, RequiredPick } from '@arbitrum/sdk/dist/lib/utils/types';
import { L1ToL2MessageGasParams } from '@arbitrum/sdk/dist/lib/message/L1ToL2MessageCreator';

export interface TokenApproveParams {
  erc721L1Address: string;
  overrides?: PayableOverrides;
}

export interface Erc721DepositParams extends EthDepositParams {
  l2Provider: Provider;
  erc721L1Address: string;
  tokenIds: BigNumber[];
  destinationAddress?: string;
  excessFeeRefundAddress?: string;
  callValueRefundAddress?: string;
  retryableGasOverrides?: GasOverrides;
  overrides?: Overrides;
}

export type L1ToL2TxReqAndSignerProvider = L1ToL2TransactionRequest & {
  l1Signer: Signer;
  overrides?: Overrides;
};

type SignerTokenApproveParams = TokenApproveParams & { l1Signer: Signer };
type ProviderTokenApproveParams = TokenApproveParams & { l1Provider: Provider };
export type ApproveParamsOrTxRequest =
  | SignerTokenApproveParams
  | {
      txRequest: Required<Pick<TransactionRequest, 'to' | 'data' | 'value'>>;
      l1Signer: Signer;
      overrides?: Overrides;
    };

type DepositRequest = OmitTyped<
  Erc721DepositParams,
  'overrides' | 'l1Signer'
> & {
  l1Provider: Provider;
  from: string;
};

type DefaultedDepositRequest = RequiredPick<
  DepositRequest,
  'callValueRefundAddress' | 'excessFeeRefundAddress' | 'destinationAddress'
>;

/**
 * Bridger for moving ERC721 tokens back and forth betwen L1 to L2
 */
export class Erc721Bridger extends AssetBridger<
  Erc721DepositParams | L1ToL2TxReqAndSignerProvider,
  any
> {
  public static MIN_CUSTOM_DEPOSIT_GAS_LIMIT = BigNumber.from(275000);

  /**
   * Bridger for moving ERC721 tokens back and forth betwen L1 to L2
   */
  public constructor(l2Network: L2Network) {
    super(l2Network);
  }

  /**
   * Get the address of the l1 gateway for this token
   * @param erc721L1Address
   * @param l1Provider
   * @returns
   */
  public async getL1GatewayAddress(
    erc721L1Address: string,
    l1Provider: Provider
  ): Promise<string> {
    await this.checkL1Network(l1Provider);

    return await L1GatewayRouter__factory.connect(
      this.l2Network.tokenBridge.l1GatewayRouter,
      l1Provider
    ).getGateway(erc721L1Address);
  }

  /**
   * Get a tx request to approve tokens for deposit to the bridge.
   * The tokens will be approved for the relevant gateway.
   * @param params
   * @returns
   */
  public async getApproveTokenRequest(
    params: ProviderTokenApproveParams
  ): Promise<Required<Pick<TransactionRequest, 'to' | 'data' | 'value'>>> {
    // you approve tokens to the gateway that the router will use
    const gatewayAddress = await this.getL1GatewayAddress(
      params.erc721L1Address,
      SignerProviderUtils.getProviderOrThrow(params.l1Provider)
    );

    const iErc721Interface = ERC721__factory.createInterface();
    const data = iErc721Interface.encodeFunctionData('setApprovalForAll', [
      gatewayAddress,
      true
    ]);

    return {
      to: params.erc721L1Address,
      data,
      value: BigNumber.from(0)
    };
  }

  private isApproveParams(
    params: ApproveParamsOrTxRequest
  ): params is SignerTokenApproveParams {
    return (params as SignerTokenApproveParams).erc721L1Address !== undefined;
  }

  /**
   * Approve tokens for deposit to the bridge. The tokens will be approved for the relevant gateway.
   * @param params
   * @returns
   */
  public async approveToken(
    params: ApproveParamsOrTxRequest
  ): Promise<ethers.ContractTransaction> {
    await this.checkL1Network(params.l1Signer);

    const approveRequest = this.isApproveParams(params)
      ? await this.getApproveTokenRequest({
          ...params,
          l1Provider: SignerProviderUtils.getProviderOrThrow(params.l1Signer)
        })
      : params.txRequest;
    return await params.l1Signer.sendTransaction({
      ...approveRequest,
      ...params.overrides
    });
  }

  private applyDefaults<T extends DepositRequest>(
    params: T
  ): DefaultedDepositRequest {
    return {
      ...params,
      excessFeeRefundAddress: params.excessFeeRefundAddress ?? params.from,
      callValueRefundAddress: params.callValueRefundAddress ?? params.from,
      destinationAddress: params.destinationAddress ?? params.from
    };
  }

  /**
   * Get the arguments for calling the deposit function
   * @param params
   * @returns
   */
  public async getDepositRequest(
    params: DepositRequest
  ): Promise<L1ToL2TransactionRequest> {
    await this.checkL1Network(params.l1Provider);
    await this.checkL2Network(params.l2Provider);
    const defaultedParams = this.applyDefaults(params);
    const {
      amount,
      destinationAddress,
      erc721L1Address,
      l1Provider,
      l2Provider,
      retryableGasOverrides,
      tokenIds
    } = defaultedParams;

    const l1GatewayAddress = await this.getL1GatewayAddress(
      erc721L1Address,
      l1Provider
    );
    let tokenGasOverrides: GasOverrides | undefined = retryableGasOverrides;

    // we also add a hardcoded minimum gas limit for custom gateway deposits
    if (l1GatewayAddress === this.l2Network.tokenBridge.l1CustomGateway) {
      if (tokenGasOverrides == null) tokenGasOverrides = {};
      if (tokenGasOverrides.gasLimit == null) tokenGasOverrides.gasLimit = {};
      if (tokenGasOverrides.gasLimit.min == null) {
        tokenGasOverrides.gasLimit.min =
          Erc721Bridger.MIN_CUSTOM_DEPOSIT_GAS_LIMIT;
      }
    }

    const depositFunc = (
      depositParams: OmitTyped<L1ToL2MessageGasParams, 'deposit'>
    ): any => {
      const extraData = defaultAbiCoder.encode(['uint256[]'], [tokenIds]);
      const innerData = defaultAbiCoder.encode(
        ['uint256', 'bytes'],
        [depositParams.maxSubmissionCost, extraData]
      );
      const iGatewayRouter = L1GatewayRouter__factory.createInterface();

      return {
        data: iGatewayRouter.encodeFunctionData('outboundTransfer', [
          erc721L1Address,
          destinationAddress,
          amount,
          depositParams.gasLimit,
          depositParams.maxFeePerGas,
          innerData
        ]),
        to: this.l2Network.tokenBridge.l1GatewayRouter,
        from: defaultedParams.from,
        value: depositParams.gasLimit
          .mul(depositParams.maxFeePerGas)
          .add(depositParams.maxSubmissionCost)
        // we dont include the l2 call value for token deposits because
        // they either have 0 call value, or their call value is withdrawn from
        // a contract by the gateway (weth). So in both of these cases the l2 call value
        // is not actually deposited in the value field
      };
    };

    const gasEstimator = new L1ToL2MessageGasEstimator(l2Provider);
    const estimates = await gasEstimator.populateFunctionParams(
      depositFunc,
      l1Provider,
      tokenGasOverrides
    );

    return {
      txRequest: {
        to: this.l2Network.tokenBridge.l1GatewayRouter,
        data: estimates.data,
        value: estimates.value,
        from: params.from
      },
      retryableData: {
        ...estimates.retryable,
        ...estimates.estimates
      },
      isValid: async () => {
        const reEstimates = await gasEstimator.populateFunctionParams(
          depositFunc,
          l1Provider,
          tokenGasOverrides
        );
        return await L1ToL2MessageGasEstimator.isValid(
          estimates.estimates,
          reEstimates.estimates
        );
      }
    };
  }

  /**
   * Execute a token deposit from L1 to L2
   * @param params
   * @returns
   */
  public async deposit(
    params: Erc721DepositParams | L1ToL2TxReqAndSignerProvider
  ): Promise<L1ContractCallTransaction> {
    await this.checkL1Network(params.l1Signer);

    // Although the types prevent should alert callers that value is not
    // a valid override, it is possible that they pass it in anyway as it's a common override
    // We do a safety check here
    if ((params.overrides as PayableOverrides | undefined)?.value != null) {
      throw new ArbSdkError(
        'L1 call value should be set through l1CallValue param'
      );
    }

    const l1Provider = SignerProviderUtils.getProviderOrThrow(params.l1Signer);
    const tokenDeposit = isL1ToL2TransactionRequest(params)
      ? params
      : await this.getDepositRequest({
          ...params,
          l1Provider,
          from: await params.l1Signer.getAddress()
        });

    const tx = await params.l1Signer.sendTransaction({
      ...tokenDeposit.txRequest,
      ...params.overrides
    });

    return L1TransactionReceipt.monkeyPatchContractCallWait(tx);
  }

  public async withdraw(): Promise<any> {}
}

export { ERC721__factory };
