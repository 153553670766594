import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import cls from 'clsx';
import { useUserWeb3 } from '@moverfinance/web3';
import styles from './header.module.css';
import { ExternalLink, WalletV2 } from 'components';
import { useWindowEvent } from 'hooks/useWindowEvent';
import { ReactComponent as Openblox } from 'assets/icons/openblox.svg';
import { priceRequest, TypePriceRequest } from 'store/price/actions';
import Account from './Account';

interface HeaderProps {
  priceRequest: TypePriceRequest;
}

const Header: React.FC<HeaderProps> = ({ priceRequest }) => {
  const { active, error } = useUserWeb3();
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(open => !open);
  };

  useWindowEvent('resize', () => {
    if (!window.matchMedia('(max-width: 768px)').matches) {
      if (open) {
        setOpen(false);
      }
    }
  });

  useEffect(() => {
    priceRequest();
  }, [priceRequest]);

  useEffect(() => {
    if (error) {
      hiddenMenu();
    }
  }, [error]);

  const hiddenMenu = () => {
    setOpen(open => !open);
  };

  useEffect(() => {
    if (open) {
      document.body.style.setProperty('overflow', 'hidden');
    } else {
      document.body.style.removeProperty('overflow');
    }
  }, [open]);

  return (
    <div className={styles.header}>
      <div className={styles.inner}>
        <div className={styles.nav}>
          <ExternalLink to="https://openblox.io" className={styles.navLogo}>
            <Openblox className={styles.navLogoIcon} />
          </ExternalLink>
          <NavLink
            className={styles.navItem}
            activeClassName={styles.navItemActive}
            to={'/assets'}
          >
            Marketplace
          </NavLink>
          <NavLink
            className={styles.navItem}
            activeClassName={styles.navItemActive}
            to={'/activity'}
          >
            Activity
          </NavLink>
        </div>
        <button className={styles.menu} onClick={toggleMenu}>
          <span
            className={cls(styles.menuIcon, {
              [styles.open]: open
            })}
          ></span>
        </button>
        <div
          className={cls(styles.info, {
            [styles.open]: open
          })}
        >
          <NavLink
            className={styles.infoItem}
            to={'/assets'}
            onClick={() => setOpen(false)}
          >
            Marketplace
          </NavLink>
          <NavLink
            className={styles.infoItem}
            to={'/activity'}
            onClick={() => setOpen(false)}
          >
            Activity
          </NavLink>
          <ExternalLink
            to="https://openblox.io/obx-page"
            className={styles.infoLink}
          >
            Get OBX
          </ExternalLink>
          {active ? <Account /> : <WalletV2 />}
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = {
  priceRequest
};
export default connect(null, mapDispatchToProps)(Header);
