import { useCallback } from 'react';
import { getL2Network } from '@arbitrum/sdk';
import { useUserWeb3, useTryConnect } from '@moverfinance/web3';
import { BigNumber } from 'ethers';
import merge from 'lodash/merge';
import { Erc721Bridger, ERC721__factory } from 'utils/erc721Bridger';
import { JsonRpcProvider } from '@ethersproject/providers';
import { NFT, TOKEN_BRIDGE, L1_RPC, L2_RPC } from 'constants/address';
// import { L2_RPC } from 'constants/migrate';

import { injected } from 'connectors/injected';

const useSigner = () => {
  const { library } = useUserWeb3();

  return useCallback(async () => {
    if (!library) throw new Error('library is not found');
    return await library.getSigner();
  }, [library]);
};

export const useDeposit = () => {
  const getSigner = useSigner();
  const tryConnect = useTryConnect();

  return useCallback(
    async (
      tokenIds: Array<number | string>,
      waitL2: boolean,
      onStep: (steps: boolean[]) => void
    ) => {
      await tryConnect(injected);
      onStep([true, false, false]);
      const amount = BigNumber.from(tokenIds.length);
      const l1Signer = await getSigner();
      const l1Provider = new JsonRpcProvider(L1_RPC);
      const l2Provider = new JsonRpcProvider(L2_RPC);
      const defaultL2Network = await getL2Network(l2Provider);
      const l2Network = {
        ...defaultL2Network,
        tokenBridge: merge(defaultL2Network.tokenBridge, TOKEN_BRIDGE)
      };

      const bridge = new Erc721Bridger(l2Network);
      const owner = await l1Signer.getAddress();
      const openBloxL1 = ERC721__factory.connect(NFT, l1Provider);
      const allowance: boolean = await openBloxL1.isApprovedForAll(
        owner,
        TOKEN_BRIDGE.l1CustomGateway as string
      );

      if (!allowance) {
        const approveTx = await bridge.approveToken({
          l1Signer: l1Signer,
          erc721L1Address: NFT
        });
        await approveTx.wait();
      }
      onStep([true, true, false]);
      const depositTx = await bridge.deposit({
        amount,
        erc721L1Address: NFT,
        l1Signer: l1Signer,
        l2Provider,
        tokenIds: tokenIds.map(id => BigNumber.from(id))
      });

      if (!waitL2) return depositTx;
      const depositRec = await depositTx.wait();
      return await depositRec.waitForL2(l2Provider);
    },
    [getSigner, tryConnect]
  );
};
