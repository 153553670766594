import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Provider as Web3Provider } from '@moverfinance/web3';
import { InjectedConnector } from '@web3-react/injected-connector';
import App from './App';
import { injected } from './connectors/injected';
import { bitkeep } from 'connectors/bitkeep';
import { history, store, persist } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import './i18n';
import 'vendor/iubenda';
import 'vendor/mixpanel';

const injectedConnector =
  localStorage.getItem('provider') === 'bitkeep' && window.isBitKeep
    ? bitkeep
    : injected;
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Web3Provider
      autoConnect
      injectedConnector={injectedConnector as InjectedConnector}
      error={<div>Network Error</div>}
    >
      <Provider store={store}>
        {
          <PersistGate loading={null} persistor={persist}>
            <App history={history} />
          </PersistGate>
        }
      </Provider>
    </Web3Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
