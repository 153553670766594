import React, { memo, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NFT, Order } from 'services';
import { formatEther } from '@ethersproject/units';
import CardRace from 'components/Card/CardRace';
import { useAuthenticated, useBalance, useBid } from 'hooks';
import { ButtonV2, ModalV2, Wallet, Price, message } from 'components';
import { track, trackName } from 'vendor/mixpanel';
import styles from './buy.module.css';

export interface BuyProps {
  nft: NFT;
  order: Order;
}

const Buy: React.FC<BuyProps> = ({ nft, order }) => {
  const { t } = useTranslation();
  const bid = useBid();
  const isAuthenticated = useAuthenticated();
  const balance = useBalance();
  const [showBuy, setShowBuy] = useState(false);
  const [buying, setBuying] = useState(false);

  const canBuy = useCallback(() => {
    return Number(nft?.order?.price) <= Number(formatEther(balance));
  }, [nft, balance]);
  const buyAsset = useCallback(async () => {
    if (order) {
      const bidOrder = order.order;
      const signature = order.signature;
      if (signature) {
        bid(bidOrder, signature.sig)
          .then(tx => {
            setBuying(true);
            return tx.wait();
          })
          .then(receipt => {
            if (receipt.status) {
              setShowBuy(false);
              setBuying(false);
              message.success('Buy success! Please update your page!');
            }
          })
          .catch(error => {
            message.warning(error.error?.message || error.message, 5);
          });
      }
    }
  }, [order, bid]);

  const checkoutText = useMemo(() => {
    if (canBuy()) {
      if (buying) {
        return t('views.asset.buying');
      } else {
        return t('views.asset.checkout');
      }
    } else {
      return t('views.asset.notEnoughFund');
    }
  }, [buying, canBuy, t]);

  return (
    <>
      <ButtonV2
        mode="primary"
        className={styles.button}
        onClick={() => {
          track(trackName.CLICK_BUY_NOW, { TokenID: nft?.tokenId });
          setShowBuy(true);
        }}
      >
        {t('views.asset.buyNow')}
      </ButtonV2>
      <ModalV2
        visible={showBuy}
        title={'Complete checkout'}
        onClose={setShowBuy}
        className={styles.modal}
      >
        {isAuthenticated ? (
          <div className={styles.buy}>
            <div className={styles.buyContent}>
              <div className={styles.buyItem}>
                <div className={styles.buyName}>
                  <div className={styles.buyCard}>
                    <img
                      alt="asset"
                      width={48}
                      height={48}
                      src={nft?.imageUrl}
                    />
                  </div>
                  <div className={styles.buyCardInfo}>
                    <div className={styles.buyCardInfoLeft}>
                      <span className={styles.buyRace}>
                        <CardRace race={nft?.properties?.race}></CardRace>
                        &nbsp;
                        {nft?.properties?.race}
                      </span>
                      <p className={styles.buyPriceETHHighlight}>
                        {`${nft?.order?.price} ${t('common.eth')}`}
                      </p>
                    </div>
                    <div className={styles.buyPrice}>
                      <span className={styles.buyId}>#{nft?.tokenId}</span>

                      {nft?.order?.price && (
                        <Price
                          eth={nft?.order.price}
                          className={styles.buyPriceUSD}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.buyItem}>
                <div className={styles.buyNameTotal}>TOTAL</div>
                <div className={styles.buyPriceFlex}>
                  <p className={styles.buyPriceETH}>
                    {nft?.order?.price}&nbsp;{t('common.eth')}
                  </p>
                  &nbsp;&nbsp;
                  {nft?.order?.price && (
                    <Price
                      eth={nft?.order?.price}
                      className={styles.buyPriceUSDNormal}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.buyFooter}>
              <ButtonV2
                onClick={buyAsset}
                disabled={!canBuy() || buying}
                mode="primary"
                className={styles.button}
              >
                {checkoutText}
              </ButtonV2>
              <ButtonV2
                className={styles.cancel}
                onClick={() => setShowBuy(false)}
              >
                {'CANCEL'}
              </ButtonV2>
            </div>
          </div>
        ) : (
          <div className={styles.connectWalet}>
            <p className={styles.connectWaletText}>
              Before buy, please connect wallet.
            </p>
            <Wallet />
          </div>
        )}
      </ModalV2>
    </>
  );
};

export default memo(Buy);
