import { useCallback, useEffect, useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserWeb3 } from '@moverfinance/web3';
import { useBalance, useWindowSize, useTransfer } from 'hooks';
import { formatEther } from '@ethersproject/units';
import { isAddress } from '@ethersproject/address';
import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { ReactComponent as NoResult } from 'assets/icons/icon-class.svg';
import { Balance, Button, Card, CardList, ExternalLink, Modal, Pagination, Price, Table, Input } from 'components';
import { NFT as NFT_ADDRESS } from 'constants/address';
import styles from './account.module.css';
import { copyText, shortenAddress, strDateTime, strEvent } from 'utils';
import { isMyAccount } from 'utils/account';
import Tippy from '@tippyjs/react';
import { Activity, listActivitiesByAddress, listNFTs, NFT } from 'services';
import { ETHERSCAN_URL, isL1NET } from '../../../constants/chains';
import { ReactComponent as Transfer } from '../../../assets/icons/transfer.svg';
import { ReactComponent as Warn } from 'assets/icons/warn.svg';
import { ReactComponent as Done } from 'assets/icons/done.svg';
import { ReactComponent as Check } from '../../../assets/icons/check.svg';
import { ReactComponent as Loading } from '../../../assets/icons/waiting.svg';
import message from '../../../components/message';
let LIMIT=32;
const MaximumCheck = 50;
const Account = () => {
  const { t } = useTranslation();
  let { account } = useUserWeb3();
  const location = useLocation(); //获取跳转页面携带的值
  let { width } = useWindowSize();
  const transfer = useTransfer();
  const [showListing, setShowListing] = useState(false);
  const [steps, setSteps] = useState<boolean[]>([false, false, false]);
  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [showBatchTransfer, setShowBatchTransfer] = useState(false);
  const [showBatchTransferConfirm, setShowBatchTransferConfirm] = useState(false);
  const [batchTransferError, setBatchTransferError] = useState("");
  const [batchTransferAddress, setBatchTransferAddress] = useState("");
  const [check, setCheck] = useState<number[]>([]);
  const [nfts, setNfts] = useState<NFT[]>([]);
  const [nftCount, setNftCount] = useState(0);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [tippyText, setTippyText] = useState<string>(t('common.copy'));
  const myAccount=isMyAccount(account, location);
  // @ts-ignore
  const owner = (location.state && location.state.owner) || account;
  account = owner;
  // @ts-ignore
  let userBalance = useBalance(account);
  useEffect(() => {
    if (account) {
      const params = {
        nftAddress:
          // @ts-ignore
          (location.state && location.state.nftAddress) || NFT_ADDRESS,
        owner: account,
        limit: LIMIT,
        page,
      };
      listNFTs(params).then(data => {
        setNftCount(data.count);
        setNfts(data.nfts);
      });
      listActivitiesByAddress({
        address: account
      }).then(data => {
        setActivities(data.activities);
      });
    }
  }, [page, account, location]);

  const batchTransfer = () => {
    if (account) {
      setShowBatchTransfer(false);
      setShowBatchTransferConfirm(false);
      setShowListing(true);
      transfer(check,batchTransferAddress, steps => {
        setSteps(steps);
      }).then(data => {
        setSteps([true, true, true]);
        setTimeout(function() {
          setShowListing(false);
          message.success('Transfer success!Please update your page!', 5);
        }, 1000)
      }).catch(error => {
        setShowListing(false);
        message.warning(
          error.message ===
          'MetaMask Tx Signature: User denied transaction signature.'
            ? 'YOU DECLINED THE ACTION IN YOUR WALLET.'
            : error.message,
          5
        );
      });
    }
  };
  const batchTransferConfirm=()=>{
    if (check.length > MaximumCheck) {
      message.warning('WE RECOMMEND TRANSFERRING A MAXIMUM OF 50 BLOXES EVERY TIME.', 5);
    }else {
      setShowBatchTransferConfirm(true);
    }
  }

  const copyAddress = () => {
    if (account && copyText(account)) {
      setTippyText(t('common.copied'));
    }
  };

  const showTippy = () => {
    setTippyText(t('common.copy'));
    setVisible(true);
  };

  const hideTipppy = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: '',
      key: 'marginL',
      width: '10px',
      render: (value: string) => {
        if (value) {
          return <span></span>;
        }
      }
    },
    {
      title: 'EVENT',
      key: 'event',
      width: '15%',
      render: (value: string) => {
        if (value) {
          return <span className={styles.event}>{value}</span>;
        }
      }
    },
    {
      title: 'PRICE',
      key: 'price',
      width: '18%',
      render: (value: string) => {
        if (value) {
          return <div>{value} eth</div>;
        }
      }
    },
    {
      title: 'FROM',
      key: 'from',
      render: (value: string) => {
        if (value) {
          // @ts-ignore
          const nftAddress = location.state && location.state.nftAddress;
          if (isL1NET()) {
            return <span>{shortenAddress(value)}</span>;
          }
          return (
            <NavLink
              to={{
                pathname: '/account',
                state: { owner: value, nftAddress: nftAddress }
              }}
              className={styles.externalLink}
            >
              {shortenAddress(value)}
            </NavLink>
          );
        } else {
          return null;
        }
      }
    },
    {
      title: 'TO',
      key: 'to',
      render: (value: string) => {
        if (value) {
          // @ts-ignore
          const nftAddress = location.state && location.state.nftAddress;
          if (isL1NET()) {
            return <span>{shortenAddress(value)}</span>;
          }
          return (
            <NavLink
              to={{
                pathname: '/account',
                state: { owner: value, nftAddress: nftAddress }
              }}
              className={styles.externalLink}
            >
              {shortenAddress(value)}
            </NavLink>
          );
        } else {
          return null;
        }
      }
    },
    {
      title: 'DATE',
      key: 'date'
    },
    {
      title: '',
      key: 'marginR',
      width: '10px',
      render: (value: string) => {
        if (value) {
          return <span></span>;
        }
      }
    }
  ];
  const handlePage = (value: number) => {
    setPage(value);
  };
  const dataSource = activities.map(activity => {
    return {
      marginL: '',
      event: strEvent(activity.event),
      // item: activity.item || '',
      price: activity.price || '',
      // qty: activity.qty || '',
      from: activity.from || '',
      to: activity.to || '',
      date: strDateTime(new Date(activity.ts)),
      marginR: ''
    };
  });
  const handleTransfer=(value: string)=>{
    console.log(value);
    console.log(account);
    if (value === account) {
      setBatchTransferError('You cannot transfer assets to your current wallet.');
    } else if (isAddress(value) || value === '') {
      setBatchTransferError('');
    } else {
      setBatchTransferError('Please confirm address');
    }
    setBatchTransferAddress(value);
  }
  const handleCheckCallback = useCallback((ids: number[]) => {
    setCheck(ids);
    setShowBatchTransfer(true);
    setShowBatchTransferConfirm(false);
    setShowListing(false);
  }, []);

  const isSmallModal = () => {
    return nftCount <= 6;
  }
  return (
    <>
      <div className={styles.account}>
        <div className={`${styles.inner}`}>
          <div className={styles.total}>
            <h1 className={styles.caption}>
              {t('views.account.totalBalance')}
            </h1>
            <div className={styles.balance}>
              <Balance className={styles.balanceETH} balance={userBalance} />
              <Price
                eth={formatEther(userBalance)}
                className={styles.balanceUSD}
              />
              <div className={styles.address}>
                <ExternalLink
                  to={`${ETHERSCAN_URL}/address/${account}`}
                  className={styles.accountAddress}
                >
                  {account}
                </ExternalLink>
                <div
                  onClick={copyAddress}
                  onMouseEnter={showTippy}
                  onMouseLeave={hideTipppy}
                >
                  <Tippy content={tippyText} visible={visible}>
                    <Copy className={styles.copy} />
                  </Tippy>
                </div>
              </div>
            </div>
            {myAccount &&
            <div className={styles.batchTransfer}>
              <div className={styles.batchTransferTitle}>Transfer</div>
              <Transfer/>
              <div className={styles.batchTransferInfo}>TRANSFER YOUR BLOXES TO OTHERS.</div>
              <Button className={'buy'} onClick={() => {
                setShowBatchTransfer(true);
                setBatchTransferError('');
                setBatchTransferAddress('');
              }} disabled={isL1NET()}>
                {t('views.account.batchTransfer')}
              </Button>
            </div>
            }
          </div>
          <div className={styles.right}>
            <h1 className={styles.captionInventory}>
              {t('views.account.inventory', {
                count: nftCount
              })}
            </h1>
            <div className={styles.rightView}>
              {nfts?.length === 0 && (
                <div className={styles.noResult}>
                  <div>
                    <NoResult />
                    <div className={styles.noResultInfo}>
                      No items to display
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.inventory}>
                {nfts?.length !== 0 && (
                  <div className={styles.inventoryList}>
                    {nfts.map(nft => {
                      return (
                        <Card
                          id={nft.tokenId}
                          ethPrice={nft.order.price}
                          lastPrice={nft.order.lastPrice}
                          onsale={nft.order.onsale}
                          owner={nft?.owner}
                          cardClickable={isL1NET() ? false : true}
                          className={isL1NET() ? styles.cursorInherit : ''}
                          sourceType={"inventory"}
                          imgSrc={
                            nft?.imageUrl || 'https://images.openblox.io/96.png'
                          }
                          heroTag={nft?.properties?.race}
                          key={nft?.tokenId}
                        />
                      );
                    })}
                  </div>
                )}
                <div className={styles.pagination}>
                  <Pagination
                    current={page}
                    total={Math.ceil(nftCount / LIMIT)}
                    onChange={handlePage}
                  />
                </div>
              </div>
              <div className={styles.activity}>
                <h1 className={styles.caption}>{'Activity'}</h1>
                {dataSource?.length === 0 && (
                  <div className={styles.noResult}>
                    <div>
                      <NoResult />
                      <div className={styles.noResultInfo}>
                        No trading history yet
                      </div>
                    </div>
                  </div>
                )}
                {dataSource?.length !== 0 && (
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    className={styles.activityTableRowSplit}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={showBatchTransfer}
        title={'Transfer your Bloxes'}
        className={!isSmallModal() ? styles.cardListModalNormal : styles.cardListModalMiddle}
        onClose={() => {
          setShowBatchTransfer(false);
        }}
      >
        <div className={styles.cardListModalContent}>
          <CardList
            showSortOptions={false}
            showSearchComponent={true}
            useCardComponent={true}
            showSmallCard={true}
            checkAble={true}
            showSmallModal={isSmallModal() ? true : false}
            checkCallback={handleCheckCallback}
            limit={isSmallModal() ? 6 : 12}
            paginationClass={`${styles.cardListModalContentPagination} ${!isSmallModal() ? styles.cardListModalContentPaginationLarge : ''}`}
          />
          <div className={styles.cardListModalContentBtn}>
            <div className={styles.cardListModalContentBtnTitle}>
              <Transfer/><span>Transfer to address</span>
            </div>
            {isSmallModal() && <div className={styles.cardListModalTransferError}>{batchTransferError}</div>}
            <div className={isSmallModal() ? styles.cardListModalBottomSmall : styles.cardListModalBottom}>
              <div className={`${styles.cardListModalContentInputView} ${!isSmallModal() ? styles.cardListModalContentInputViewLarge : '' }`}>
                {!isSmallModal() && <div className={styles.cardListModalTransferError}>{batchTransferError}</div>}
                <Input inputChange={handleTransfer} className={`${styles.cardListModalContentInput} ${!isSmallModal() ? styles.cardListModalContentInputLarge : '' }`} placeholder={"e.g. 430a24...3f6cbb"}></Input>
                {batchTransferError !== '' && <Warn></Warn>}
                {batchTransferError === '' && batchTransferAddress && <Done></Done>}
              </div>
              <Button
                className={`${(batchTransferError === '' && batchTransferAddress && check.length > 0) ? styles.cardListModalTransferBtn : styles.cardListModalTransferBtnDisable}`}
                disabled={(batchTransferError === '' && batchTransferAddress && check.length>0) ? false : true}
                onClick={() => batchTransferConfirm()}
              >
                {t('views.account.batchTransferBtn')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={showBatchTransferConfirm}
        title={'Confirm your transfer'}
        className={!isSmallModal() ? styles.cardListModalNormal : styles.cardListModalMiddle}
        onClose={() => {
          setShowBatchTransfer(false);
          setShowBatchTransferConfirm(false);
        }}
      >
        <div className={styles.cardListModalContent}>
          <CardList
            showSortOptions={false}
            showSearchComponent={false}
            useCardComponent={true}
            showSmallCard={true}
            tokenIds={check.join(",")}
            showSmallModal={isSmallModal() ? true : false}
            limit={isSmallModal() ? 6 : 12}
            paginationClass={`${styles.cardListModalContentPagination} ${!isSmallModal() ? styles.cardListModalContentPaginationLarge : ''}`}
          />
          <div className={styles.cardListModalContentBtn}>
            <div className={isSmallModal() ? styles.cardListModalBottomSmall : styles.cardListModalBottom}>
              <div className={`${styles.cardListModalContentConfirm} ${!isSmallModal() ? styles.cardListModalContentConfirmLarge : '' }`}>
                <span>{check.length} bloxes will be transfer to</span>
                <div>{batchTransferAddress}</div>
              </div>
            </div>
            {width > 720 &&
            <div
              style={isSmallModal() ? { display: 'flex', alignItems: 'center', justifyContent: 'space-between' } : {}}>
              <Button
                className={`backBtn ${isSmallModal() ? styles.cardListModalBtnBackSmall : styles.cardListModalBtnBack}`}
                onClick={() => {
                  setShowBatchTransferConfirm(false)
                }}
              >
                {t('views.account.batchTransferBtnBack')}
              </Button>
              <Button
                style={{ width: '242px' }}
                className={`${(batchTransferError === '' && batchTransferAddress && check.length > 0) ? styles.cardListModalTransferBtn : styles.cardListModalTransferBtnDisable}`}
                disabled={(batchTransferError === '' && batchTransferAddress && check.length > 0) ? false : true}
                onClick={() => batchTransfer()}
              >
                {t('views.account.batchTransferBtnConfirm')}
              </Button>
            </div>
            }
            {width <= 720 &&
            <div>
              <Button
                className={`${(batchTransferError === '' && batchTransferAddress && check.length > 0) ? styles.cardListModalTransferBtn : styles.cardListModalTransferBtnDisable} fullWidth`}
                disabled={(batchTransferError === '' && batchTransferAddress && check.length > 0) ? false : true}
                onClick={() => batchTransfer()}
              >
                {t('views.account.batchTransferBtnConfirm')}
              </Button>
              <Button
                className={`backBtn ${isSmallModal() ? styles.cardListModalBtnBackSmall : styles.cardListModalBtnBack} fullWidth`}
                onClick={() => {
                  setShowBatchTransferConfirm(false)
                }}
              >
                {t('views.account.batchTransferBtnBack')}
              </Button>
            </div>
            }
          </div>
        </div>
      </Modal>

      <Modal
        visible={showListing}
        title={'Complete your transfer'}
        closable={false}
      >
        <div className={styles.steps}>
          <div className={styles.step}>
            <h2 className={styles.stepTitle}>
              {steps[0] ? <Check /> : <Loading />}
              <span className={styles.stepTitleText}>
                INTIALIZE YOUR WALLET
              </span>
            </h2>
            <p className={styles.stepContent}>
              To use our Marketplace for the first time you will need to initialize your wallet, which requires a one time gas fee.
            </p>
          </div>
          <div className={styles.step}>
            <h2 className={styles.stepTitle}>
              {steps[1] ? <Check /> : <Loading />}
              <span className={styles.stepTitleText}>APPROVE NFT</span>
            </h2>
            <p className={styles.stepContent}>
              Approving the NFTs for transfer sets up for arbitrum bridge. This requires a one time gas fee.
            </p>
          </div>
          <div className={styles.step}>
            <h2 className={styles.stepTitle}>
              {steps[2] ? <Check /> : <Loading />}
              <span className={styles.stepTitleText}>
                CONFIRM TRANSFER
              </span>
            </h2>
            <p className={styles.stepContent}>
              Confirm the transfer by accepting the signature request in your wallet.
            </p>
            {steps[2] && (
              <p className={styles.stepSuccess}>Waiting for signature...</p>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Account;
