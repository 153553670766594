const script = document.createElement('script');
script.type = 'text/javascript';
script.innerHTML = '(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);';
script.async = true;
document.body.appendChild(script);
const script2 = document.createElement('script');
script2.type = 'text/javascript';
script2.innerHTML = 'var _iub = _iub || [];\n' +
  '    _iub.csConfiguration = {"ccpaAcknowledgeOnDisplay":true,"ccpaApplies":true,"consentOnContinuedBrowsing":false,"enableCcpa":true,"floatingPreferencesButtonDisplay":"bottom-right","invalidateConsentWithoutLog":true,"lang":"en","perPurposeConsent":true,"siteId":2552740,"whitelabel":false,"cookiePolicyId":30870666, "banner":{ "acceptButtonDisplay":true,"closeButtonRejects":true,"customizeButtonDisplay":true,"explicitWithdrawal":true,"position":"float-top-center","rejectButtonDisplay":true }};';
script2.async = true;
document.body.appendChild(script2);
const script3 = document.createElement('script');
script3.type = 'text/javascript';
script3.src = '//cdn.iubenda.com/cs/ccpa/stub.js';
script3.async = true;
document.body.appendChild(script3);
const script4 = document.createElement('script');
script4.type = 'text/javascript';
script4.src = '//cdn.iubenda.com/cs/iubenda_cs.js';
script4.async = true;
document.body.appendChild(script4);

export {};
