import React, { useEffect, useState, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserWeb3, useTryConnect, isMobile } from '@moverfinance/web3';
import { UnsupportedChainIdError } from '@web3-react/core';
import styles from './wallet.module.css';
import { ExternalLink, ModalV2, ButtonV2 } from 'components';
import { track, trackName } from 'vendor/mixpanel';
import { injected } from 'connectors/injected';
import walletConnect from 'connectors/walletconnect';
import { bitkeep } from 'connectors/bitkeep';
import { runCallback, hasWallet } from 'utils';
import { ReactComponent as MetaMask } from 'assets/icons/metamask.svg';
import { ReactComponent as BitKeep } from 'assets/icons/bitkeep.svg';
import { ReactComponent as WalletConnect } from 'assets/icons/walletconnect.svg';

export interface WalletProps {
  hiddenMenu?: () => void;
  showNetSelect?: boolean;
}

const Wallet: React.FC<WalletProps> = ({
  hiddenMenu,
  showNetSelect = false
}) => {
  const { t } = useTranslation();
  const { active, error } = useUserWeb3();
  const [callback, setCallback] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const tryConnect = useTryConnect();

  const connectMetaMask = useCallback(() => {
    track(trackName.CONNECT_WALLET);
    tryConnect(injected);
    localStorage.setItem('provider', 'injected');
    setShowWallet(false);
  }, [tryConnect]);

  const connectWalletConnect = useCallback(() => {
    track(trackName.CONNECT_WALLET);
    tryConnect(walletConnect);
    localStorage.setItem('provider', 'walletConnect');
    setShowWallet(false);
  }, [tryConnect]);

  const connectBitKeep = useCallback(() => {
    if (window.isBitKeep) {
      track(trackName.CONNECT_WALLET);
      tryConnect(bitkeep);
      localStorage.setItem('provider', 'bitkeep');
    } else {
      window.open(
        'https://chrome.google.com/webstore/detail/bitkeep-bitcoin-crypto-wa/jiidiaalihmmhddjgbnbgdfflelocpak'
      );
    }
    setShowWallet(false);
  }, [tryConnect]);

  const connectWallet = useCallback(() => {
    if (isMobile) {
      connectMetaMask();
    } else {
      setShowWallet(true);
    }
  }, [connectMetaMask]);

  useEffect(() => {
    if (callback || !isMobile) return;
    if (error) {
      hiddenMenu && runCallback(hiddenMenu, false);
      setCallback(true);
    }
  }, [error, hiddenMenu, callback]);

  return (
    <>
      {hasWallet() ? (
        !active && isMobile ? (
          <div className={styles.walletConnect}>
            {showNetSelect && (
              <div className={styles.walletMenu}>
                <div className={styles.mobileMenu}>
                  <NavLink
                    className={styles.mobileMenuItem}
                    activeClassName={styles.mobileMenuItemActive}
                    to={'/assets'}
                  >
                    <div className={styles.mobileMenuItemDisabled}>
                      Marketplace
                    </div>
                  </NavLink>
                  <NavLink
                    className={styles.mobileMenuItem}
                    activeClassName={styles.mobileMenuItemActive}
                    to={'/activity'}
                  >
                    <div className={styles.mobileMenuItemDisabled}>
                      Activity
                    </div>
                  </NavLink>
                  <a
                    href="https://docs.openblox.io/"
                    className={styles.mobileMenuItem}
                  >
                    Whitepaper
                  </a>
                </div>
              </div>
            )}
            {showNetSelect && (
              <div className={styles.walletInfo}>
                <button
                  className={`${
                    error instanceof UnsupportedChainIdError
                      ? styles.walletDisabled
                      : styles.wallet
                  }`}
                  onClick={() => {
                    track(trackName.CONNECT_WALLET);
                    tryConnect(injected);
                  }}
                >
                  {t('common.connectWallet')}
                </button>
              </div>
            )}
            {!showNetSelect && (
              <button
                className={`${
                  error instanceof UnsupportedChainIdError
                    ? styles.walletDisabled
                    : styles.wallet
                }`}
                onClick={() => {
                  track(trackName.CONNECT_WALLET);
                  tryConnect(injected);
                }}
              >
                {t('common.connectWallet')}
              </button>
            )}
          </div>
        ) : !active ? (
          <div className={styles.walletFlex}>
            <button
              className={`${
                error instanceof UnsupportedChainIdError
                  ? styles.walletDisabled
                  : styles.wallet
              }`}
              onClick={connectWallet}
            >
              {t('common.connectWallet')}
            </button>
          </div>
        ) : (
          ''
        )
      ) : isMobile ? (
        <div className={styles.walletTop}>
          {showNetSelect && (
            <div className={styles.walletMenu}>
              <div className={styles.mobileMenu}>
                <NavLink
                  className={styles.mobileMenuItem}
                  activeClassName={styles.mobileMenuItemActive}
                  to={'/assets'}
                >
                  <div className={styles.mobileMenuItemDisabled}>
                    Marketplace
                  </div>
                </NavLink>
                <NavLink
                  className={styles.mobileMenuItem}
                  activeClassName={styles.mobileMenuItemActive}
                  to={'/activity'}
                >
                  <div className={styles.mobileMenuItemDisabled}>Activity</div>
                </NavLink>
                <a
                  href="https://docs.openblox.io/"
                  className={styles.mobileMenuItem}
                >
                  Whitepaper
                </a>
              </div>
            </div>
          )}
          <div className={styles.walletInfo}>
            <ExternalLink
              className={styles.wallet}
              to={`https://metamask.app.link/dapp/${document.domain}`}
            >
              {t('common.connectWallet')}
            </ExternalLink>
          </div>
        </div>
      ) : (
        <ExternalLink
          to="https://metamask.io/download.html"
          className={styles.wallet}
        >
          {t('common.installMetaMask')}
        </ExternalLink>
      )}
      <ModalV2
        visible={showWallet}
        title={'Connect to wallet'}
        onClose={setShowWallet}
        className={styles.modal}
      >
        <div className={styles.modalInner}>
          <ButtonV2
            mode="normal"
            className={styles.modalButton}
            onClick={connectMetaMask}
          >
            <MetaMask className={styles.modalIcon} />
            METAMASK
          </ButtonV2>
          <ButtonV2
            mode="normal"
            className={styles.modalButton}
            onClick={connectBitKeep}
          >
            <BitKeep className={styles.modalIcon} />
            BITKEEP
          </ButtonV2>
          <ButtonV2
            mode="normal"
            className={styles.modalButton}
            onClick={connectWalletConnect}
          >
            <WalletConnect className={styles.modalIcon} />
            WALLETCONNECT
          </ButtonV2>
        </div>
      </ModalV2>
    </>
  );
};
export default Wallet;
