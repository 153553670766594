import { useEffect, useState } from 'react';
import styles from './activity.module.css';
import { Pagination, Price, TableV2 } from 'components';
import { isL1NET } from '../../../constants/chains';
import { shortenAddress, strDateTime, strEvent } from '../../../utils';
import { Activity as Activities, listActivities } from 'services';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const LIMIT = 20;

const Activity = () => {
  const [activities, setActivities] = useState<Activities[]>([]);
  const [page, setPage] = useState(1);
  const [autoFetch, setAutoFetch] = useState(0);
  const [count, setCount] = useState(0);
  const [filter] = useState('buy');
  const { t } = useTranslation();
  useEffect(() => {
    const auto = setTimeout(() => {
      let params = {
        limit: LIMIT,
        page: page,
      };
      let event = { event: filter };
      if (filter) params = { ...params, ...event };
      listActivities(params).then(data => {
        setActivities(data.activities);
        setCount(data.count);
        setAutoFetch(autoFetch + 0.0001);
      });
    }, autoFetch === 0 ? 0 : 20000);
    return () => {
      clearTimeout(auto);
    };
  }, [page, filter, autoFetch]);

  const columns = [
    {
      title: 'TYPE',
      key: 'event',
      width: '10%',
      render: (value: string) => {
        if (value) {
          return <span className={styles.event}>{value}</span>;
        }
      }
    },
    {
      title: 'ITEM',
      key: 'item',
      width: '20%',
      render: (nft: Activities) => {
        if (nft?.tokenId) {
          return <NavLink className={styles.activityListItem} to={`/assets/${nft?.tokenId}`}>
            <div>
              <img src={nft?.imageUrl} alt={'card'}></img>
            </div>
            <span>Blox #{nft?.tokenId}</span>
          </NavLink>;
        }
      }
    },
    {
      title: 'PRICE',
      renderTitle: (value: string) => {
        return <div className={styles.activityListHeaderPrice}>{value}</div>
      },
      key: 'price',
      width: '15%',
      render: (value: string) => {
        if (value) {
          return <div className={styles.activityListPrice}>
            <Price eth={value} className={styles.activityListPriceETH}/>
            <div className={styles.activityListPriceUSD}>{`${value} ${t(
              'common.eth'
            )}`}</div>
          </div>;
        } else {
          return <div className={styles.activityListPrice} style={{ color: '#909090' }}>-</div>;
        }
      }
    },
    {
      title: 'FROM',
      key: 'from',
      width: '22%',
      render: (value: string) => {
        if (value) {
          if (isL1NET()) {
            return <span>{shortenAddress(value, 10)}</span>;
          }
          return (
            <NavLink to={{
              pathname: '/account',
              state: { owner: value }
            }} className={styles.externalLink}>
              {shortenAddress(value, 10)}
            </NavLink>
          );
        } else {
          return <span style={{ color: '#909090' }}>-</span>;
        }
      }
    },
    {
      title: 'TO',
      key: 'to',
      width: '22%',
      render: (value: string) => {
        if (value) {
          if (isL1NET()) {
            return <span>{shortenAddress(value, 10)}</span>;
          }
          return (
            <NavLink to={{
              pathname: '/account',
              state: { owner: value }
            }} className={styles.externalLink}>
              {shortenAddress(value, 10)}
            </NavLink>
          );
        } else {
          return <span style={{ color: '#909090' }}>-</span>;
        }
      }
    },
    {
      title: 'DATE',
      key: 'date',
      width: '11%',
      render: (value: string) => {
        return (
          <span className={styles.activityListDate}>{value}</span>
        );
      }
    }
  ];

  const handlePage = (value: number) => {
    setAutoFetch(0);
    setPage(value);
  };
  const dataSource = activities.map(activity => {
    return {
      event: strEvent(activity.event),
      item: activity || '',
      price: activity.price || '',
      from: activity.from || '',
      to: activity.to || '',
      date: strDateTime(new Date(activity.ts))
    };
  });
  return (
      <div className={styles.activity}>
        <div className={styles.header}>
          <div className={styles.headerInner}>
            <div className={styles.headerTitle}>Activities</div>
            <div className={styles.headerSort}>
             {/* <Select
                value={filter}
                options={filterOptions}
                onChange={handleSelectFilter}
                className={styles.select}
              />*/}
            </div>
          </div>
        </div>
        <div className={styles.tableList}>
          <div className={styles.tableListWrapper}>
            <TableV2
              columns={columns}
              dataSource={dataSource}
            />
          </div>
        </div>
        <div className={styles.pagination}>
          <div className={styles.paginationInner}>
            <Pagination
              current={page}
              total={Math.ceil(count / LIMIT)}
              onChange={handlePage}
            />
          </div>
        </div>
      </div>
  );
};

export default Activity;
